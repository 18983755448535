<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['acneDiscomfortFrequency'])">
      <single-answer-question
        title="label.pimpleDiscomfortFrequency"
        :value="acneDiscomfortFrequency"
        :options="$options.discomfortOptions"
        dysfunction="pimples"
        @input="onFieldChange('acneDiscomfortFrequency', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap
      class="q-mt24 q-mt32-md"
      :error="getFieldsError(['firstAcneOccurrence'])"
    >
      <single-answer-question
        id="first-acne-occurrence"
        title="label.firstPimpleOccurrence"
        :value="firstAcneOccurrence"
        :options="$options.occurenceOptions"
        dysfunction="pimples"
        @input="onFieldChange('firstAcneOccurrence', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';
import { ACNE_DURATION, ACNE_FREQUENCY } from '@/modules/questionnaire/api/constants';

const DISCOMFORT_OPTIONS = [
  { value: ACNE_FREQUENCY.UNDER_A_WEEK, text: 'pimpleDiscomfortFrequency.underAWeek' },
  { value: ACNE_FREQUENCY.TWO_TO_THREE_WEEKS, text: 'pimpleDiscomfortFrequency.twoToThreeWeeks' },
  { value: ACNE_FREQUENCY.ALL_THE_TIME, text: 'pimpleDiscomfortFrequency.allTheTime' }
];

const OCCURENCE_OPTIONS = [
  { value: ACNE_DURATION.UNDER_A_YEAR, text: 'firstPimpleOccurrence.underAYear' },
  { value: ACNE_DURATION.ONE_TO_THREE_YEARS, text: 'firstPimpleOccurrence.oneToThreeYears' },
  { value: ACNE_DURATION.OVER_THREE_YEARS, text: 'firstPimpleOccurrence.overThreeYears' }
];

export default {
  name: 'PersistenceTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion },
  discomfortOptions: DISCOMFORT_OPTIONS,
  occurenceOptions: OCCURENCE_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    acneDiscomfortFrequency: {
      type: String,
      default: ''
    },
    firstAcneOccurrence: {
      type: String,
      default: ''
    }
  }
};
</script>
